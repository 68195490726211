.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button:single-button:decrement {
  background: #000042;
  border-radius: 5px;
  border: 1px solid #aaa;
  height: 10px; /* Height of the up button */
  width: 10px; /* width of the down button */
  display: block;
  cursor: pointer;
}

/* Arrow Buttons: Bottom/Down */
::-webkit-scrollbar-button:single-button:increment {
  background: #000042;
  border-radius: 5px;
  border: 1px solid #aaa;
  height: 10px; /* Height of the down button */
  width: 10px; /* width of the down button */

  display: block;
  cursor: pointer;
}

::-webkit-scrollbar-button:hover {
  background: linear-gradient(to bottom, #bbb, #999);
  border: 1px solid #888;
}

/* Hide the button (optional if not needed) */
::-webkit-scrollbar-button:single-button {
  display: none; /* Use if you want to remove the arrows */
}

::-webkit-scrollbar-thumb {
  background: #000042;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #fbe57b;
  cursor: pointer;
}

.Scrollleft::-webkit-scrollbar-thumb {
  background: #8f7912;
  border-radius: 20px;
}
.Scrollleft::-webkit-scrollbar-thumb:hover {
  background: #fbe57b;
  border-radius: 20px;
  cursor: pointer;
}
.Scrollleft {
  overflow-x: auto;
  direction: rtl;
}
.Scrollleft div {
  direction: ltr;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*access control css*/
.module-access-control {
  font-family: inherit; /* Use the same font-family as the div */
  font-size: inherit; /* Inherit the font size from the parent div */
  color: inherit; /* Inherit color from the parent div */
  font-family: "Poppins";
  font-size: 20px;
}

.module-access-control,
h2 {
  font-family: inherit; /* Use the same font-family as the div */
  font-size: inherit; /* Inherit the font size from the parent div */
  color: inherit; /* Inherit color from the parent div */
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 900;
}

.module-access-control table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd; /* Border for the table */
  font-size: 12px; /* Adjust the font size if needed */
  font-family: "Poppins";
}

.module-access-control th,
.module-access-control td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid #ddd; /* Border for table cells */
}

.module-access-control th {
  background-color: #f4f4f4;
  /* font-weight: bold; */
}

.module-access-control td {
  background-color: #fff;
}

.module-access-control input[type="checkbox"] {
  accent-color: #000042;
  margin: 0; /* Ensure no extra margin around checkboxes */
  transform: scale(1.2); /* Optional: Increase size of checkboxes */
}

.checkbox-size {
  width: 15px; /* Adjust the width */
  height: 15px; /* Adjust the height */
}

/* Optional: Adjust the table cell to align checkbox properly */
.table-checkbox-cell {
  text-align: center;
}

.MuiDayCalendar-header {
  /* height: 32px; */
}
.MuiDayCalendar-root {
  width: 100%;
}
.MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label {
  font-size: 12px;
}
.MuiYearCalendar-root.css-1vs7z2v-MuiYearCalendar-root {
  width: 100%;
}

.bounce_animation {
  animation: myAnim 1s ease-in-out 0s 1 normal forwards;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  margin: 0;
}

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 
@keyframes myAnim {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
} */
