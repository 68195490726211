

.ql-html-buttonGroup{
    background-color: black;
}
.ql-container{
    background-color: white;
    color: black;
}
.ql-html-textArea .ql-container{
    background-color: aqua !important;
    
}

.ql-editor{
    height: 70vh;
}