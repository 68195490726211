@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

input:focus {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  overscroll-behavior: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

div {
  user-select: none;
}

.ql-container {
  font-family: "Arial";
  font-size: 12px;
}

/* Set content font-families */

/* Set dropdown font-families */
#toolbar-container .ql-font span[data-label="Arial"]::before {
  font-family: "Arial";
}
#toolbar-container .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}
#toolbar-container .ql-font span[data-label="Raleway"]::before {
  font-family: "Raleway";
}
#toolbar-container .ql-font span[data-label="Montserrat"]::before {
  font-family: "Montserrat";
}
#toolbar-container .ql-font span[data-label="Lato"]::before {
  font-family: "Lato";
}
#toolbar-container .ql-font span[data-label="Rubik"]::before {
  font-family: "Rubik";
}

/* Set content font-families */
.ql-font-arial {
  font-family: "Arial";
}
.ql-font-roboto {
  font-family: "Roboto";
}
.ql-font-raleway {
  font-family: "Raleway";
}
.ql-font-montserrat {
  font-family: "Montserrat";
}
.ql-font-lato {
  font-family: "Lato";
}
.ql-font-rubik {
  font-family: "Rubik";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
  font-size: 9px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before {
  content: "9";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before {
  content: "11";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "16";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {
  content: "22";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: "24";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before {
  content: "26";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before {
  content: "28";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: "roboto";
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before {
  content: "raleway";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="montserrat"]::before {
  content: "montserrat";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lato"]::before {
  content: "lato";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="rubik"]::before {
  content: "rubik";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "arial";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before {
  content: "Georgia";
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Courier-Prime"]::before {
  content: "Courier Prime";
  font-family: "Courier New", Courier, monospace;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "serif";
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Noto-Sans-Tamil"]::before {
  content: "Noto Sans Tamil";
  font-family: "Noto Sans Tamil", sans-serif;
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="times-new-roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman", Times, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before {
  content: "roboto";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before {
  content: "raleway";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="montserrat"]::before {
  content: "montserrat";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lato"]::before {
  content: "lato";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="rubik"]::before {
  content: "rubik";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before {
  content: "arial";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before {
  content: "Georgia";
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Courier-Prime"]::before {
  content: "Courier Prime";
  font-family: "Courier New", Courier, monospace;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before {
  content: "serif";
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Noto-Sans-Tamil"]::before {
  content: "Noto Sans Tamil";
  font-family: "Noto Sans Tamil", sans-serif;
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="times-new-roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman", Times, serif;
}

.ql-size-9px {
  font-size: 9px;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-16px {
  font-size: 16px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-20px {
  font-size: 20px;
}
.ql-size-22px {
  font-size: 22px;
}
.ql-size-24px {
  font-size: 24px;
}
.ql-size-26px {
  font-size: 26px;
}
.ql-size-28px {
  font-size: 28px;
}

.line-dotted {
  border-style: dotted;
}
.line-solid {
  border-style: solid;
}
.line-double {
  border-style: double;
}

.ql-snow .ql-picker.ql-box .ql-picker-label::before,
.ql-snow .ql-picker.ql-box .ql-picker-item::before {
  content: "None";
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value="solid"]::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value="solid"]::before {
  content: "Solid";
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value="double"]::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value="double"]::before {
  content: "Double";
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value="dotted"]::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value="dotted"]::before {
  content: "Dotted";
}

.ql-snow span.ql-picker.ql-box {
  width: 8em;
}
